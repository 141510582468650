/**
 * ChartListPage template that...
 *
 */
import * as React from 'react'
import { graphql, Link } from 'gatsby'
import * as uuid from 'react-uuid'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ContentCard from '../components/content-card'
import Pager from '../components/pager'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import useContentData from '../hooks/use-content-data'
import { ContentNode } from '../types/content'
import { ChartData } from '../api/chart'

const ChartListPage = ({ data, pageContext }) => {
  //console.log('-----ChartListPage: ', data, pageContext)
  const charts = data.allChartJson.edges
  const { currentPage, numPages } = pageContext
  const pageTitle = 'Chart List'

  return (
    <Layout>
      <Seo title={pageTitle} />
      {/*       <header className="l-header">
        <div className="l-banner-image">
          <GatsbyImage
            imgClassName="banner-image"
            image={getImage(data.bannerImage)}
            alt="Colorful chalk sticks — free license by Sharon McCutcheon from Unsplash.com"
          />
        </div>
        <div className="l-banner-overlay"></div>
        <div className="l-banner-content">
          <div className="l-constrained text-light text-shadow">
            <h1 id="page-title" className="page-title">
              {pageTitle}{" "}
              {currentPage === 1
                ? null
                : ` - Page ${currentPage} of ${numPages}`}
            </h1>
            <p className="image-caption">
              Photo by {` `}
              <a
                href="https://pixabay.com/"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Sharon McCutcheon
              </a>
            </p>
          </div>
        </div>
      </header> */}
      <main className="l-main">
        <div className="l-constrained text-highlight">
          <h1>{pageTitle}</h1>
          <section>
            <ul>
              {charts.map(({ node }) => {
                return (
                  <li key={uuid()}>
                    <Link to={node.handle}>{node.title}</Link>
                  </li>
                )
              })}
            </ul>
            <Pager {...pageContext} />
          </section>
        </div>
      </main>
    </Layout>
  )
}

export default ChartListPage

export const chartListQuery = graphql`
  query paginatedChartListQuery($skip: Int!, $limit: Int!) {
    allChartJson(
      filter: { fields: { collection: { eq: "charts" } } }
      sort: { order: ASC, fields: [handle] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          handle
          description
          chartType
          source
          tags
          status
          created_at
          updated_at
          published_at
        }
      }
      totalCount
    }
  }
`
